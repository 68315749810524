import React from "react";
import OptimizedImage from "../../components/divers/OptimizedImage";
import { NavLink } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

function NavMobile() {
  return (
    <div className="container-fluid nav_sm_md">
      <div className="row">
        <nav className="col navbar navbar-dark nav_petit_ft">
          <NavLink className="navbar-brand" to="/" aria-label="Accueil">
            <div className="titre">
              <picture className="logo">
                <OptimizedImage
                  src="/images-site-web/logos_dessins/logo_clair.webp"
                  alt="logo lord of the books"
                />
              </picture>
            </div>
          </NavLink>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <ul
            className="navbar-nav collapse navbar-collapse text-center"
            id="navbarNav"
          >
            <li className="nav-item deroulant">
              <div className="nav-link">- Services -</div>
              <ul className="sous sous_PT_FT">
                <li>
                  <NavLink
                    to="/restaurations-de-livres-anciens"
                    className="nav-link"
                    aria-label="Restauration"
                  >
                    Restauration
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/reproductions-de-livres-d-art"
                    className="nav-link"
                    aria-label="Reproduction"
                  >
                    Reproduction
                  </NavLink>
                </li>
              </ul>
            </li>
            <li className="nav-item deroulant">
              <div className="nav-link">- Réalisations -</div>
              <ul className="sous sous_PT_FT">
                <li>
                  <NavLink
                    to="/reproductions-et-restaurations-litteraires"
                    className="nav-link"
                    aria-label="réalisations"
                  >
                    Réalisations
                  </NavLink>
                </li>
                <li>
                  <HashLink
                    className="nav-link"
                    to="/reproductions-et-restaurations-litteraires#tolkien"
                    aria-label="Tolkien"
                  >
                    Tolkien
                  </HashLink>
                </li>
                <li>
                  <HashLink
                    className="nav-link"
                    to="/reproductions-et-restaurations-litteraires#enluminures"
                    aria-label="Enluminures Médiévales"
                  >
                    Enluminures Médiévales
                  </HashLink>
                </li>
                <li>
                  <HashLink
                    className="nav-link"
                    to="/reproductions-et-restaurations-litteraires#grimoire"
                    aria-label="Grimoire de Magie Noire et Occultisme"
                  >
                    Grimoire de Magie Noire et Occultisme
                  </HashLink>
                </li>
                <li>
                  <HashLink
                    className="nav-link"
                    to="/reproductions-et-restaurations-litteraires#alchimie"
                    aria-label="Alchimie Historique"
                  >
                    Alchimie Historique
                  </HashLink>
                </li>
                <li>
                  <HashLink
                    className="nav-link"
                    to="/reproductions-et-restaurations-litteraires#harry"
                    aria-label="Harry Potter"
                  >
                    Harry Potter
                  </HashLink>
                </li>
                <li>
                  <HashLink
                    className="nav-link"
                    to="/reproductions-et-restaurations-litteraires#repliques"
                    aria-label="Répliques de Films et Séries"
                  >
                    Répliques de Films et Séries
                  </HashLink>
                </li>
                <li>
                  <HashLink
                    className="nav-link"
                    to="/reproductions-et-restaurations-litteraires#cartographie"
                    aria-label="Cartographie Fantastique"
                  >
                    Cartographie Fantastique
                  </HashLink>
                </li>
                <li>
                  <HashLink
                    className="nav-link"
                    to="/reproductions-et-restaurations-litteraires#calligraphie"
                    aria-label="Calligraphie Historique"
                  >
                    Calligraphie Historique
                  </HashLink>
                </li>
              </ul>
            </li>
            <li className="nav-item deroulant">
              <div className="nav-link">- Atelier -</div>
              <ul className="sous sous_PT_FT">
                <li>
                  <NavLink
                    to="/atelier-de-restauration-et-de-reproduction"
                    className="nav-link"
                    aria-label="Atelier"
                  >
                    Atelier
                  </NavLink>
                </li>
                <li>
                  <HashLink
                    to="/atelier-de-restauration-et-de-reproduction#restauration"
                    className="nav-link"
                    aria-label="restauration"
                  >
                    Restauration
                  </HashLink>
                </li>
                <li>
                  <HashLink
                    to="/atelier-de-restauration-et-de-reproduction#ouvrages"
                    className="nav-link"
                    aria-label="ouvrages"
                  >
                    Ouvrages
                  </HashLink>
                </li>
                <li>
                  <HashLink
                    to="/atelier-de-restauration-et-de-reproduction#materiaux"
                    className="nav-link"
                    aria-label="matériaux"
                  >
                    Matériaux
                  </HashLink>
                </li>
                <li>
                  <HashLink
                    to="/atelier-de-restauration-et-de-reproduction#materiels"
                    className="nav-link"
                    aria-label="matériels"
                  >
                    Matériels
                  </HashLink>
                </li>
              </ul>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/contact" aria-label=" contact">
                - Contact -
              </NavLink>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
}

export default NavMobile;
