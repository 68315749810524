import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Home from "./pages/Home";

// 3️⃣ Router singleton created
const router = createBrowserRouter([
  { path: "/", Component: Home },
  {
    path: "restaurations-de-livres-anciens",
    async lazy() {
      let { Restaurations } = await import("./pages/Restaurations");
      return { Component: Restaurations };
    },
  },
  {
    path: "restaurations-de-livres-anciens",
    async lazy() {
      let { Restaurations } = await import("./pages/Restaurations");
      return { Component: Restaurations };
    },
  },
  {
    path: "reproductions-de-livres-d-art",
    async lazy() {
      let { Reproductions } = await import("./pages/Reproductions");
      return { Component: Reproductions };
    },
  },

  {
    path: "reproductions-et-restaurations-litteraires",
    async lazy() {
      let { Realisations } = await import("./pages/Realisations");
      return { Component: Realisations };
    },
  },

  {
    path: "atelier-de-restauration-et-de-reproduction",
    async lazy() {
      let { Atelier } = await import("./pages/Atelier");
      return { Component: Atelier };
    },
  },

  {
    path: "contact",
    async lazy() {
      let { Contact } = await import("./pages/Contact");
      return { Component: Contact };
    },
  },

  {
    path: "administratif/cgv",
    async lazy() {
      let { Cgv } = await import("./pages/administratif/Cgv");
      return { Component: Cgv };
    },
  },
  {
    path: "administratif/mentions-legales",
    async lazy() {
      let { Mentions } = await import("./pages/administratif/Mentions");
      return { Component: Mentions };
    },
  },
  {
    path: "administratif/confidentialite",
    async lazy() {
      let { Confidentialite } = await import(
        "./pages/administratif/Confidentialite"
      );
      return { Component: Confidentialite };
    },
  },

  {
    path: "*",
    async lazy() {
      let { NotFound } = await import("./pages/NotFound");
      return { Component: NotFound };
    },
  },
]);

// 4️⃣ RouterProvider added
function App() {
  return <RouterProvider router={router} />;
}

export default App;
