import React from "react";
import TransitionsBottomBlack from "../../components/transitions/BottomBlack";
import TransitionsTopDark from "../../components/transitions/TopDark";
import OptimizedImage from "../../components/divers/OptimizedImage";

function HeaderLogo() {
  return (
    <div className="container-fluid slider_transition">
      {/* TRANSITION ET SLIDER */}
      <div className="row">
        <div className="col-12 slider_transition">
          <div className="transition_naviguation">
            <TransitionsBottomBlack />
          </div>
          <div className="slider">
            <picture className="slider1 kenburns-bottom">
              <OptimizedImage
                src="/images-site-web/logo-main.webp"
                alt="logo lord of the books dessiné à la main sur du papier parchemin"
              />
            </picture>
          </div>
          <div className="transition_slider">
            <TransitionsTopDark />
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeaderLogo;
