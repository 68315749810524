import React from 'react'
import { Helmet } from "react-helmet";

export default function MetaHome() {
  return (
    <Helmet>
    <title>
      Restaurateur de livres anciens - Lord of the books by Arnaud Hosse
    </title>
    <meta name="description" content="Offrez à vos livres précieux une seconde vie grâce à mon expertise de restaurateur de livres anciens." />
    <link rel="canonical" href="https://thelordofthebooks.fr" />
    <meta
      property="og:title"
      content="Restaurateur de livres anciens - Lord of the books by Arnaud Hosse"
    />
    <meta property="og:description" content="Offrez à vos livres précieux une seconde vie grâce à mon expertise de restaurateur de livres anciens." />
    <meta property="og:type" content="siteweb" />
    <meta property="og:site_name" content="The Lord of the books" />
    <meta property="og:url" content="https://thelordofthebooks.fr" />
    <script type="application/ld+json">{`
      {
        "@context": "https://schema.org/",
        "@type": "Service",
        "logo": "https://thelordofthebooks.fr/images/logo-main.webp",
        "@id":"https://thelordofthebooks.fr",
        "url":"https://thelordofthebooks.fr",
        "name": "Restaurateur de livres anciens - Lord of the books by Arnaud Hosse",
        "description": "Offrez à vos livres précieux une seconde vie grâce à mon expertise de restaurateur de livres anciens."
      }
    `}</script>
  </Helmet>
)
}
