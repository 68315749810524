import React from "react";
import SectionsNavCenter from "./NavCenter";
import SectionsNavContact from "./NavContact";
import OptimizedImage from "../../components/divers/OptimizedImage";
import { NavLink } from "react-router-dom";

function NavDesktop() {
  return (
    <div className="container-fluid nav_lg_xl_xxl">
      <div className="row">
        <nav className="nav_grand_ft justify-content-between">
          <NavLink className="nav-link" to="/" aria-label="Accueil">
            <div className="titre ms-md-3">
              <picture className="logo">
                <OptimizedImage
                  src="/images-site-web/logos_dessins/logo_clair.webp"
                  alt="logo lord of the books"
                />
              </picture>
            </div>
          </NavLink>

          <SectionsNavCenter />

          <SectionsNavContact />
        </nav>
      </div>
    </div>
  );
}

export default NavDesktop;
