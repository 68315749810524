import React from "react";
import OptimizedImage from "../divers/OptimizedImage";

function BottomBlack() {
  return (
    <picture className="transition_bottom">
      <OptimizedImage
        src="/images-site-web/transitions/bottom_black.webp"
        alt="transition"
      />
    </picture>
  );
}

export default BottomBlack;
