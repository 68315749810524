import React from "react";
import Imgix from "react-imgix";

const OptimizedImage = ({ src, alt }) => {
  return (
    <Imgix
      src={`https://thelordofthebooks.fr${src}`}
      width={400}
      height={300}
      type="responsive"
      htmlAttributes={{
        alt: alt,
      }}
    />
  );
};

export default OptimizedImage;
