import React from "react";
import { NavLink } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

function NavCenter() {
  return (
    <ul className="nav justify-content-center nav-pills">
      <li className="nav-item deroulant">
        <div className="nav-link">- Services -</div>
        <ul className="sous sous_GD_FT">
          <li>
            <NavLink
              to="/restaurations-de-livres-anciens"
              className="nav-link"
              aria-label="Restauration"
            >
              Restauration
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/reproductions-de-livres-d-art"
              className="nav-link"
              aria-label="Reproduction"
            >
              Reproduction
            </NavLink>
          </li>
        </ul>
      </li>
      <li className="nav-item deroulant">
        <NavLink
          className="nav-link"
          to="/reproductions-et-restaurations-litteraires"
          aria-label="Réalisations"
        >
          - Réalisations -
        </NavLink>
        <ul className="sous sous_GD_FT">
          <li>
            <HashLink
              className="nav-link"
              to="/reproductions-et-restaurations-litteraires#tolkien"
              aria-label="Tolkien"
            >
              Tolkien
            </HashLink>
          </li>
          <li>
            <HashLink
              className="nav-link"
              to="/reproductions-et-restaurations-litteraires#enluminures"
              aria-label="Enluminures Médiévales"
            >
              Enluminures Médiévales
            </HashLink>
          </li>
          <li>
            <HashLink
              className="nav-link"
              to="/reproductions-et-restaurations-litteraires#grimoire"
              aria-label="Grimoire de Magie et Occultisme"
            >
              Grimoire de Magie Noire et Occultisme
            </HashLink>
          </li>
          <li>
            <HashLink
              className="nav-link"
              to="/reproductions-et-restaurations-litteraires#alchimie"
              aria-label="Alchimie"
            >
              Alchimie Historique
            </HashLink>
          </li>
          <li>
            <HashLink
              className="nav-link"
              to="/reproductions-et-restaurations-litteraires#harry"
              aria-label="Harry Potter"
            >
              Harry Potter
            </HashLink>
          </li>
          <li>
            <HashLink
              className="nav-link"
              to="/reproductions-et-restaurations-litteraires#repliques"
              aria-label="Répliques de films et séries"
            >
              Répliques de Films et Séries
            </HashLink>
          </li>
          <li>
            <HashLink
              className="nav-link"
              to="/reproductions-et-restaurations-litteraires#cartographie"
              aria-label="Cartographie"
            >
              Cartographie Fantastique
            </HashLink>
          </li>
          <li>
            <HashLink
              className="nav-link"
              to="/reproductions-et-restaurations-litteraires#calligraphie"
              aria-label="Calligraphie"
            >
              Calligraphie Historique
            </HashLink>
          </li>
        </ul>
      </li>
      <li className="nav-item deroulant">
        <NavLink
          className="nav-link"
          to="/atelier-de-restauration-et-de-reproduction"
          aria-label="Atelier"
        >
          - Atelier -
        </NavLink>
        <ul className="sous sous_GD_FT">
          <li>
            <HashLink
              to="/atelier-de-restauration-et-de-reproduction#restauration"
              aria-label="restauration"
              className="nav-link"
            >
              Restauration
            </HashLink>
          </li>
          <li>
            <HashLink
              to="/atelier-de-restauration-et-de-reproduction#ouvrages"
              aria-label="ouvrages"
              className="nav-link"
            >
              Ouvrages
            </HashLink>
          </li>
          <li>
            <HashLink
              to="/atelier-de-restauration-et-de-reproduction#materiaux"
              aria-label="Matériaux"
              className="nav-link"
            >
              Matériaux
            </HashLink>
          </li>
          <li>
            <HashLink
              to="/atelier-de-restauration-et-de-reproduction#materiels"
              aria-label="Matériels"
              className="nav-link"
            >
              Matériels
            </HashLink>
          </li>
        </ul>
      </li>
    </ul>
  );
}

export default NavCenter;
