import React from "react";
import Imgix from "react-imgix";

const OptimizedImageLazy = ({ src, alt }) => {
  return (
    <Imgix
      src={`https://thelordofthebooks.fr${src}`}
      width={400}
      height={300}
      type="responsive"
      className="lazyload"
      htmlAttributes={{
        alt: alt,
      }}
    />
  );
};

export default OptimizedImageLazy;
