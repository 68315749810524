import React from "react";
import { NavLink } from "react-router-dom";

function NavContact() {
  return (
    <ul className="nav justify-content-end">
      <li className="nav-item">
        <NavLink
          className="btn mx-auto bouton_contactMenu me-3"
          to="/contact"
          role="button"
          aria-label="Contactez-moi"
        >
          Contactez-moi
        </NavLink>
      </li>
    </ul>
  );
}

export default NavContact;
