import React, { Suspense, lazy } from "react";
import SectionHeader from "../components/sections/Header";
import ScrollToTop from "../components/effects/ScrollToTop";

const SectionsAvisClients = lazy(() =>
  import("../components/sections/AvisClients")
);
const SectionsContactSocial = lazy(() =>
  import("../components/sections/ContactSocial")
);
const SectionsFooter = lazy(() => import("../components/sections/Footer"));

function BaseLayout({ children }) {
  return (
    <>
      <ScrollToTop />
      <SectionHeader />
      {children}
      <Suspense fallback={"<div>Loading...</div>"}>
        <SectionsAvisClients />
        <SectionsContactSocial />
        <SectionsFooter />
      </Suspense>
    </>
  );
}

export default BaseLayout;
