import React from "react";
import { NavLink } from "react-router-dom";
import OptimizedImageLazy from "../../components/divers/OptimizedImageLazy";

function Presentation() {
  return (
    <section className="presentation">
      <div className="container">
        <div className="row presentation_before p-3 ombre-interne">
          <div className="col-12 col-lg-6 p-0 p-lg-3 d-flex align-content-between flex-wrap flex-row-reverse">
            <h1 className="text-end p-0 mb-3 mb-lg-5">
              <span>Restaurateur</span> <br />
              de livres anciens
            </h1>
            <p className="text-end p-0 mb-3 mb-lg-5">
              Passionné d’histoire médiévale et relieur doreur d’art de métier,
              j’utilise mon savoir-faire pour donner vie à des créations uniques
              dans le respect du travail artisanal ancestral en tant que
              restaurateur de livres anciens. <br />
              <br />
              Que ce soit par le biais de mondes merveilleux, créés par Tolkien,
              Pratchett, ou d’autres créateurs d’univers incroyables, voire même
              notre merveilleuse histoire. <br />
              <br />
              J’exprime un artisanat entièrement manuel, afin de donner vie au
              légendaire grâce aux techniques de travail d’époque, dans un souci
              d’authenticité constante.
            </p>
            <div className="w-100">
              <div className="d-sm-flex flex-sm-row justify-content-evenly text-end text-lg-center">
                <NavLink
                  className="btn bouton_savoir-faire mt-3 mt-sm-3 mt-md-5 m-lg-0"
                  to="/restaurations-de-livres-anciens"
                  role="button"
                  aria-label="Une restauration"
                >
                  Une restauration ?
                </NavLink>
                <NavLink
                  className="btn bouton_savoir-faire mt-2 mt-sm-3 mt-md-5 m-lg-0"
                  to="/reproductions-de-livres-d-art"
                  role="button"
                  aria-label="Une reproduction"
                >
                  Une reproduction ?
                </NavLink>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-6 mt-3 mt-lg-0 p-0 p-lg-3">
            <div className="photo_presentation">
              <picture id="presentation">
                <OptimizedImageLazy
                  src="/images-site-web/atelier/photo-atelier_1.webp"
                  alt="Présentation de l'atelier d'Arnaud, restaurateur de livres anciens et fondateur de Lord of the books"
                />
              </picture>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Presentation;
