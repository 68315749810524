import React from "react";
import OptimizedImage from "../divers/OptimizedImage";

function TopDark() {
  return (
    <picture className="transition_top">
      <OptimizedImage
        src="/images-site-web/transitions/top_dark.webp"
        alt="transition"
      />
    </picture>
  );
}

export default TopDark;
