import React from "react";
import SectionNavDesktop from "../sections/NavDesktop";
import SectionNavMobile from "../sections/NavMobile";

function Header() {
  return (
    <header>
      <SectionNavDesktop />
      <SectionNavMobile />
    </header>
  );
}

export default Header;
