import React, { Suspense, lazy, useEffect } from "react";
import { useLocation } from "react-router-dom";
import BaseLayout from "../layout/BaseLayout";
import HeaderLogo from "../settings/home/HeaderLogo";
import Presentation from "../settings/home/Presentation";
import MetaHome from "../meta/MetaHome";

const Realisation = lazy(() => import("../settings/home/Realisation"));
const SavoirFaire = lazy(() => import("../settings/home/SavoirFaire"));
const TransitionsTopBrown = lazy(() =>
  import("../components/transitions/TopBrown")
);

export default function Home() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <MetaHome />
      <BaseLayout>
        <HeaderLogo />
        <Presentation />
        <Suspense fallback={"<div>Loading...</div>"}>
          <Realisation />
          <SavoirFaire />
          <TransitionsTopBrown />
        </Suspense>
      </BaseLayout>
    </>
  );
}
